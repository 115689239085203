import { AsYouType, isValidPhoneNumber } from "libphonenumber-js";

import { InputField, type InputFieldProps } from "./InputField";

export const validatePhoneNumber = (text: string): boolean => {
  return isValidPhoneNumber(text, "US");
};

export const formatPhoneNumber = (text: string): string => {
  if (text.includes("(") && !text.includes(")")) {
    return text.replace("(", "");
  }

  return new AsYouType("US").input(text);
};

export const PhoneNumberField = (props: InputFieldProps) => {
  return (
    <InputField
      formatInput={formatPhoneNumber}
      inputMode="numeric"
      keyboardType="number-pad"
      {...props}
    />
  );
};
