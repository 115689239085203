import { useTsController } from "@ts-react/form";
import { useId } from "react";
import { useFormContext } from "react-hook-form";
import { Card, Text, XStack, YStack } from "tamagui";
import type { CardProps } from "tamagui";

import { Paragraph2 } from "../Typography";
import { Fieldset } from "./Fieldset";
import { FormError } from "./FormError";

const defaultCardWidth = 175;
const defaultCardHeight = 120;

const defaultContainerWidth = defaultCardWidth * 2 + 13;

export type CardMultiSelectOption = {
  id: string;
  icon: string;
  label: string;
};

export type CardMultiSelectItemProps = {
  id: string;
  icon: string;
  label: string;
  isSelected?: boolean;
  width: number;
  height: number;
} & CardProps;

export const CardMultiSelectItem = ({
  id,
  icon,
  label,
  isSelected,
  width,
  height,
  ...props
}: CardMultiSelectItemProps) => {
  return (
    <Card
      id={id}
      width={width}
      height={height}
      borderColor={isSelected ? "$borderColorFocus" : "$borderColor"}
      borderWidth={isSelected ? "$1" : "$0.5"}
      padding={isSelected ? 23 : "$5"}
      {...props}
    >
      <YStack gap="$2" alignItems="center" justifyContent="center">
        <Text fontSize="$7" textAlign="center">
          {icon}
        </Text>
        <Paragraph2 textAlign="center">{label}</Paragraph2>
      </YStack>
    </Card>
  );
};

export type CardMultiSelectFieldProps = {
  id: string;
};

export const CardMultiSelectField = ({
  options,
}: {
  options: CardMultiSelectOption[];
}) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<string[]>();

  const { resetField } = useFormContext();

  const id = useId();

  const disabled = isSubmitting;

  function toggleField(option: string) {
    const currentValue = field.value || [];

    if (currentValue.includes(option)) {
      const filtered = currentValue.filter(item => item !== option);

      if (filtered.length === 0) {
        resetField(field.name, { defaultValue: [] });
      }

      field.onChange(filtered);
    } else {
      field.onChange([...currentValue, option]);
    }
  }

  return (
    <Fieldset>
      <XStack flex={1} alignContent="center" justifyContent="center">
        <XStack
          id={id}
          gap="$3"
          flexWrap="wrap"
          justifyContent="flex-start"
          paddingTop="$5"
          paddingBottom="$10"
          maxWidth={defaultContainerWidth}
        >
          {options.map(option => {
            return (
              <CardMultiSelectItem
                width={defaultCardWidth}
                height={defaultCardHeight}
                disabled={disabled}
                key={option.id}
                label={option.label}
                id={option.id}
                icon={option.icon}
                onPress={() => toggleField(option.id)}
                isSelected={field.value?.includes(option.id) ? true : false}
              />
            );
          })}
        </XStack>
      </XStack>
      <FormError errorMessage={error?.errorMessage} />
    </Fieldset>
  );
};
