import { useFieldInfo, useTsController } from "@ts-react/form";
import { useId } from "react";
import { Fieldset, Label, TextArea, type TextAreaProps, Theme } from "tamagui";

import { FormError } from "./FormError";
import { useFormFieldOptions } from "./FormFieldOptionsProvider";

export const TextAreaField = (
  props: Pick<
    TextAreaProps,
    "size" | "autoFocus" | "placeholder" | "height" | "overflow"
  >,
) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<string>();
  const { label, isOptional, placeholder } = useFieldInfo();
  const { hideOptionalLabel } = useFormFieldOptions();
  const id = useId();
  const disabled = isSubmitting;

  return (
    <Theme name={error ? "red_alt2" : "granted"} forceClassName>
      <Fieldset>
        {!!label && (
          <Label size={props.size || "$3"} pb="$1" pl={0}>
            {label}
            {isOptional && !hideOptionalLabel && ` (Optional)`}
          </Label>
        )}
        <TextArea
          disabled={disabled}
          // Empty string instead of undefined so that the component
          // does not transition from "unmanaged" to "managed" (which
          // displays an error log in the web console).
          value={field.value ?? ""}
          borderWidth="$0.5"
          margin="$0.5"
          onChangeText={text => field.onChange(text)}
          onBlur={field.onBlur}
          ref={field.ref}
          placeholder={placeholder}
          key={`${id}_input`}
          rows={5}
          focusStyle={{
            borderColor: "$borderColorFocus",
            borderWidth: "$1",
            margin: 0,
          }}
          // temp fix
          // height={150}
          {...props}
        />
        <FormError errorMessage={error?.errorMessage} />
      </Fieldset>
    </Theme>
  );
};
