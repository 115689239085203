import { useTsController } from "@ts-react/form";
import { useId, useMemo } from "react";
import type React from "react";
import { useFormContext } from "react-hook-form";
import {
  Card,
  type CardProps,
  Text,
  XStack,
  YStack,
  getTokenValue,
} from "tamagui";

import { Paragraph2 } from "../Typography";
import { Fieldset } from "./Fieldset";
import { FormError } from "./FormError";

const defaultCardWidth = 175;
const defaultCardHeight = 120;

export type CardSelectOption = {
  id: string;
  icon: string | React.ReactNode;
  label: string;
};

export type CardSelectItemProps = {
  id: string;
  icon: string | React.ReactNode;
  label: string;
  isSelected?: boolean;
  width: number;
  height: number;
} & CardProps;

export const CardSelectItem = ({
  id,
  icon,
  label,
  isSelected,
  width,
  height,
  ...props
}: CardSelectItemProps) => {
  return (
    <Card
      id={id}
      width={width}
      height={height}
      borderColor={isSelected ? "$borderColorFocus" : "$borderColor"}
      borderWidth={isSelected ? "$1" : "$0.5"}
      padding={isSelected ? 23 : "$5"}
      {...props}
    >
      <YStack gap="$3" alignItems="center" justifyContent="center">
        {typeof icon === "string" ? (
          <Text fontSize="$7" textAlign="center">
            {icon}
          </Text>
        ) : (
          icon
        )}
        <Paragraph2 textAlign="center">{label}</Paragraph2>
      </YStack>
    </Card>
  );
};

export type CardSelectFieldProps = {
  native?: boolean;
};

export const CardSelectField = ({
  options,
}: {
  options: CardSelectOption[];
} & Pick<CardSelectFieldProps, "native">) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<string>();

  const gapWidth: number = Number(getTokenValue("$space.3"));

  const defaultContainerWidth = useMemo(
    () => defaultCardWidth * 2 + gapWidth,
    [gapWidth],
  );

  const { resetField } = useFormContext();

  const id = useId();

  const disabled = isSubmitting;

  // This function does not currently support de-selecting a card
  // It is intended to be used when we want to fire an event when a
  // card is selected and then submit.
  function handleSelect(option: string) {
    // marks the field as dirty
    resetField(field.name);
    field.onChange(option);
  }

  return (
    <Fieldset>
      <XStack flex={1} alignContent="center" justifyContent="center">
        <XStack
          id={id}
          gap="$3"
          flexWrap="wrap"
          justifyContent="flex-start"
          paddingTop="$5"
          paddingBottom="$10"
          maxWidth={defaultContainerWidth}
        >
          {options.map(option => {
            return (
              <CardSelectItem
                width={defaultCardWidth}
                height={defaultCardHeight}
                disabled={disabled}
                key={option.id}
                label={option.label}
                id={option.id}
                icon={option.icon}
                onPress={() => handleSelect(option.id)}
                isSelected={field.value === option.id}
              />
            );
          })}
        </XStack>
      </XStack>
      <FormError errorMessage={error?.errorMessage} />
    </Fieldset>
  );
};
