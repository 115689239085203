import { useNumberFieldInfo, useTsController } from "@ts-react/form";
import React, { useId } from "react";
import type { TextInput } from "react-native";

import { BentoInput } from "../bento/inputParts";
import { Fieldset } from "./Fieldset";
import { FormError } from "./FormError";
import { useFormFieldOptions } from "./FormFieldOptionsProvider";
import type { InputFieldProps } from "./InputField";

export const NumberField = (props: InputFieldProps) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<number>();
  const { label, defaultValue, isOptional, placeholder, minValue, maxValue } =
    useNumberFieldInfo();
  const { hideOptionalLabel } = useFormFieldOptions();
  const { size, disabled: disabledProp, ...inputProps } = props;
  const inputRef = React.useRef<TextInput | null>(null);
  const id = useId();
  const inputId = `${id}_input`;
  const disabled = isSubmitting || disabledProp;

  const setInputFocus = () => {
    if (inputRef.current && !inputRef.current.isFocused()) {
      inputRef.current.focus();
    }
  };

  return (
    <Fieldset>
      <BentoInput
        theme={error ? "red_alt2" : "granted"}
        size={size || "$4"}
        key={inputId}
      >
        {!!label && (
          <BentoInput.Label htmlFor={inputId}>
            {label}
            {isOptional && !hideOptionalLabel && ` (Optional)`}
          </BentoInput.Label>
        )}
        <BentoInput.Box onPress={() => setInputFocus()} disabled={disabled}>
          <BentoInput.Area
            id={inputId}
            placeholder={placeholder}
            overflow="hidden"
            flex={1}
            inputMode="numeric"
            keyboardType="number-pad"
            value={field.value?.toString() || ""}
            onChangeText={text => {
              const num = Number(text);
              if (isNaN(num)) {
                if (!field.value) {
                  field.onChange(defaultValue);
                }
                return;
              }
              if (typeof maxValue !== "undefined" && num > maxValue) {
                field.onChange(minValue);
                return;
              }
              if (typeof minValue !== "undefined" && num < minValue) {
                field.onChange(minValue);
                return;
              }

              field.onChange(num);
            }}
            onBlur={field.onBlur}
            disabled={disabled}
            ref={ref => {
              field.ref(ref);
              inputRef.current = ref;
            }}
            {...inputProps}
          />
        </BentoInput.Box>
        <FormError errorMessage={error?.errorMessage} />
      </BentoInput>
    </Fieldset>
  );
};
