import { AnimatePresence, XStack, YStack } from "tamagui";

import { Info } from "@tamagui/lucide-icons";

import { BentoInput } from "../bento/inputParts";

export interface FormErrorProps {
  errorMessage: string | undefined;
}

export const FormError = ({ errorMessage }: FormErrorProps) => {
  errorMessage;
  return (
    <XStack position="relative" width="100%">
      <AnimatePresence>
        {/* This block pushes down any content below the error, while the
            second block uses absolute positioning to animate the text */}
        {errorMessage && (
          <YStack
            height="$1.5"
            enterStyle={{ height: 0 }}
            exitStyle={{ height: 0 }}
            animation="bouncy"
          ></YStack>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {errorMessage && (
          <XStack
            py="$1.5"
            top={0}
            id={`form-error${errorMessage}`}
            key={`form-error${errorMessage}`}
            left={0}
            position="absolute"
            gap="$1.5"
            width="100%"
            flexDirection="row"
            animation="bouncy"
            alignItems="flex-start"
            scaleY={1}
            enterStyle={{
              opacity: 0,
              y: -10,
              scaleY: 0.5,
            }}
            exitStyle={{
              opacity: 0,
              y: -10,
              scaleY: 0.5,
            }}
            theme="red"
          >
            <BentoInput.Icon padding={0} pl="$1">
              <Info color="$color10" />
            </BentoInput.Icon>
            <BentoInput.Info
              color="$color10"
              f={1}
              textWrap="wrap"
              fontSize="$2"
              fontWeight={500}
            >
              {errorMessage}
            </BentoInput.Info>
          </XStack>
        )}
      </AnimatePresence>
    </XStack>
  );
};
