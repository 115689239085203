import { useFieldInfo, useTsController } from "@ts-react/form";
import { useId } from "react";
import {
  Adapt,
  Fieldset,
  Label,
  Select,
  type SelectProps,
  Sheet,
  Theme,
  XStack,
  YStack,
  getFontSize,
  isWeb,
} from "tamagui";

import { LinearGradient } from "@tamagui/linear-gradient";
import { Check, ChevronDown, ChevronUp } from "@tamagui/lucide-icons";

import { FieldError } from "../FieldError";
import { useFormFieldOptions } from "./FormFieldOptionsProvider";

type SelectItem = {
  value: string;
  name: string;
};

export const SelectField = ({
  options,
  native = true,
  ...props
}: {
  options: SelectItem[];
} & Pick<SelectProps, "size" | "native">) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<string>();
  const { label, isOptional } = useFieldInfo();
  const { hideOptionalLabel } = useFormFieldOptions();
  const id = useId();
  const disabled = isSubmitting;

  return (
    <Theme name={error ? "red_alt2" : "granted"} forceClassName>
      <Fieldset>
        {!!label && (
          <Label size={props.size || "$3"} htmlFor={id} pb="$1" pl={0}>
            {label}
            {isOptional && !hideOptionalLabel && ` (Optional)`}
          </Label>
        )}
        <Select
          native={native}
          id={id}
          value={field.value}
          onValueChange={field.onChange}
          {...props}
        >
          <Select.Trigger
            width="100%"
            iconAfter={ChevronDown}
            bw={field.value ? "$1" : "$0.5"}
            m={field.value ? "$0" : "$0.5"}
            boc={field.value ? "$borderColorFocus" : "$borderColor"}
          >
            <Select.Value />
          </Select.Trigger>

          <Adapt when="sm" platform="touch">
            <Sheet native modal dismissOnSnapToBottom>
              <Sheet.Frame>
                <Sheet.ScrollView>
                  <Adapt.Contents />
                </Sheet.ScrollView>
              </Sheet.Frame>
              <Sheet.Overlay />
            </Sheet>
          </Adapt>

          <Select.Content zIndex={200000}>
            <Select.ScrollUpButton
              alignItems="center"
              justifyContent="center"
              position="relative"
              width="100%"
              height="$3"
            >
              <YStack zIndex={10}>
                <ChevronUp size={20} />
              </YStack>
              <LinearGradient
                start={[0, 0]}
                end={[0, 1]}
                fullscreen
                colors={["$background", "$backgroundTransparent"]}
                borderRadius="$4"
              />
            </Select.ScrollUpButton>

            <Select.Viewport minWidth={200}>
              <XStack als="flex-start">
                <Select.Group disabled={disabled}>
                  {/* <Select.Label>{label}</Select.Label> */}
                  {options.map((item, i) => {
                    return (
                      <Select.Item index={i} key={item.name} value={item.value}>
                        <Select.ItemText>{item.name}</Select.ItemText>
                        <Select.ItemIndicator marginLeft="auto">
                          <Check size={16} />
                        </Select.ItemIndicator>
                      </Select.Item>
                    );
                  })}
                </Select.Group>
                {/* special icon treatment for native */}
                {native && isWeb && (
                  <YStack
                    fullscreen
                    alignItems="center"
                    justifyContent="center"
                    width="$4"
                    pointerEvents="none"
                  >
                    <ChevronDown
                      size={getFontSize((props.size ?? "$true") as number)}
                    />
                  </YStack>
                )}
              </XStack>
            </Select.Viewport>

            <Select.ScrollDownButton
              alignItems="center"
              justifyContent="center"
              position="relative"
              width="100%"
              height="$3"
            >
              <YStack zIndex={10}>
                <ChevronDown size={20} />
              </YStack>
              <LinearGradient
                start={[0, 0]}
                end={[0, 1]}
                fullscreen
                colors={["$backgroundTransparent", "$background"]}
                borderRadius="$4"
              />
            </Select.ScrollDownButton>
          </Select.Content>
        </Select>
        <FieldError message={error?.errorMessage} />
      </Fieldset>
    </Theme>
  );
};
