import { useFieldInfo, useTsController } from "@ts-react/form";
import { useId } from "react";
import {
  Checkbox,
  type CheckboxProps,
  type CheckedState,
  Fieldset,
  Label,
  Theme,
  XStack,
} from "tamagui";

import { Check } from "@tamagui/lucide-icons";

import { FormError } from "./FormError";
import { useFormFieldOptions } from "./FormFieldOptionsProvider";

export const BooleanCheckboxField = (
  props: Pick<CheckboxProps, "size" | "native">,
) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<CheckedState>();
  const { label, isOptional } = useFieldInfo();
  const { hideOptionalLabel } = useFormFieldOptions();
  const id = useId();
  const inputId = `${id}_boolean_checkbox_input`;
  const disabled = isSubmitting;

  return (
    <Theme name={error ? "red_alt2" : "granted"} forceClassName>
      <Fieldset>
        <XStack gap="$4">
          {!!label && (
            <Label htmlFor={inputId}>
              {label}
              {isOptional && !hideOptionalLabel && ` (Optional)`}
            </Label>
          )}
          <Checkbox
            disabled={disabled}
            native
            checked={field.value}
            onCheckedChange={checked => field.onChange(checked)}
            ref={field.ref}
            id={inputId}
            {...props}
          >
            <Checkbox.Indicator>
              <Check />
            </Checkbox.Indicator>
          </Checkbox>
        </XStack>
        <FormError errorMessage={error?.errorMessage} />
      </Fieldset>
    </Theme>
  );
};
