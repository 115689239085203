import { useFieldInfo, useTsController } from "@ts-react/form";
import React, { useId } from "react";
import { type TextInput } from "react-native";
import { type InputProps } from "tamagui";

import { BentoInput } from "../bento/inputParts";
import { Fieldset } from "./Fieldset";
import { FormError } from "./FormError";
import { useFormFieldOptions } from "./FormFieldOptionsProvider";

export type InputFieldProps = Pick<
  InputProps,
  | "size"
  | "autoFocus"
  | "disabled"
  | "onSubmitEditing"
  | "inputMode"
  | "keyboardType"
  | "maxLength"
  | "placeholder"
>;

export const InputField = (
  props: InputFieldProps & {
    formatInput?: (text: string) => string;
  },
) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<string>();
  const { formatInput, size, disabled: disabledProp, ...inputProps } = props;
  const { label, placeholder, isOptional } = useFieldInfo();
  const { hideOptionalLabel } = useFormFieldOptions();
  const inputRef = React.useRef<TextInput | null>(null);
  const id = useId();
  const inputId = `${id}_input`;
  const disabled = isSubmitting || disabledProp;

  const setInputFocus = () => {
    if (inputRef.current && !inputRef.current.isFocused()) {
      inputRef.current.focus();
    }
  };

  return (
    <Fieldset>
      <BentoInput
        theme={error ? "red_alt2" : "granted"}
        size={size || "$4"}
        key={inputId}
      >
        {!!label && (
          <BentoInput.Label htmlFor={inputId}>
            {label}
            {isOptional && !hideOptionalLabel && ` (Optional)`}
          </BentoInput.Label>
        )}
        <BentoInput.Box onPress={() => setInputFocus()} disabled={disabled}>
          <BentoInput.Area
            id={inputId}
            placeholder={placeholder}
            overflow="hidden"
            flex={1}
            // Empty string instead of undefined so that the component
            // does not transition from "unmanaged" to "managed" (which
            // displays an error log in the web console).
            value={field.value ?? ""}
            onChangeText={text => {
              field.onChange(formatInput?.(text) ?? text);
            }}
            onBlur={field.onBlur}
            disabled={disabled}
            ref={ref => {
              field.ref(ref);
              inputRef.current = ref;
            }}
            {...inputProps}
          />
        </BentoInput.Box>
        <FormError errorMessage={error?.errorMessage} />
      </BentoInput>
    </Fieldset>
  );
};
