import { useFieldInfo, useTsController } from "@ts-react/form";
import { type FunctionComponent, useId } from "react";
import {
  Fieldset,
  Label,
  RadioGroup,
  type RadioGroupProps,
  type SizeTokens,
  Theme,
  XStack,
  YStack,
} from "tamagui";

import { type IconProps } from "@tamagui/helpers-icon";

import { Paragraph2, ParagraphSmall } from "../Typography";
import { FormError } from "./FormError";
import { useFormFieldOptions } from "./FormFieldOptionsProvider";

type RadioGroupItem = {
  value: number;
  label: string;
  icon?: FunctionComponent<IconProps>;
  subLabel?: string;
};

function GroupItem({
  value,
  label,
  subLabel,
  icon: Icon,
  selected,
}: RadioGroupItem & { selected: boolean }) {
  const id = `radiogroup-${value}`;
  return (
    <XStack
      boc={selected ? "$borderColorFocus" : "$borderColor"}
      backgroundColor={selected ? "$backgroundFocus" : "$background"}
      bw={selected ? "$1" : "$0.5"}
      margin={selected ? "$0" : "$0.5"}
      br="$4"
      paddingHorizontal={selected ? 12 : "$3"}
      paddingVertical="$3"
      w="100%"
    >
      <RadioGroup.Item
        value={value.toString()}
        id={id}
        unstyled
        width="100%"
        height="100%"
      >
        <XStack alignItems="center" gap="$4">
          {Icon ? (
            <Icon
              size="$1.5"
              color={selected ? "$color10" : "$color8"}
              strokeWidth={selected ? 2 : 1}
            />
          ) : null}
          <YStack gap="$2" pt="$1.5" pb="$1">
            <Paragraph2>{label}</Paragraph2>
            {subLabel && (
              <ParagraphSmall theme="alt1" lineHeight={12}>
                {subLabel}
              </ParagraphSmall>
            )}
          </YStack>
        </XStack>
      </RadioGroup.Item>
    </XStack>
  );
}

export const AuthenticationRadioGroupField = ({
  options,
  native = false,
  ...props
}: {
  options: RadioGroupItem[];
  size?: SizeTokens;
} & Pick<RadioGroupProps, "native">) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<number>();
  const { label, isOptional } = useFieldInfo();
  const { hideOptionalLabel } = useFormFieldOptions();
  const id = useId();
  const disabled = isSubmitting;

  return (
    <Theme name={error ? "red_alt3" : "granted"} forceClassName>
      <Fieldset>
        {!!label && (
          <Label
            theme="alt1"
            size={props.size || "$3"}
            htmlFor={`${id}_input`}
            pl="$0"
          >
            {label}
            {isOptional && !hideOptionalLabel && ` (Optional)`}
          </Label>
        )}

        <RadioGroup
          disabled={disabled}
          aria-labelledby="Select one item"
          value={field.value ? field.value.toString() : ""}
          onValueChange={incoming =>
            incoming ? field.onChange(parseInt(incoming)) : null
          }
          id={`${id}_input`}
          key={`${id}_input`}
          native={native}
          name="form"
        >
          <YStack alignItems="center" space="$4">
            {options.map(item => {
              return (
                <GroupItem
                  key={item.value}
                  value={item.value}
                  label={item.label}
                  subLabel={item.subLabel}
                  selected={field.value === item.value}
                  icon={item.icon}
                />
              );
            })}
          </YStack>
        </RadioGroup>
        <FormError errorMessage={error?.errorMessage} />
      </Fieldset>
    </Theme>
  );
};
