import { useFieldInfo, useTsController } from "@ts-react/form";
import { useId } from "react";
import {
  Fieldset,
  Label,
  RadioGroup,
  type RadioGroupProps,
  type SizeTokens,
  Theme,
  XStack,
  YStack,
} from "tamagui";

import { Paragraph2 } from "../Typography";
import { FormError } from "./FormError";
import { useFormFieldOptions } from "./FormFieldOptionsProvider";

type RadioGroupItem = {
  value: string;
  label: string;
  subLabel?: string;
};

export function RadioGroupItemWithLabels({
  idBase,
  size,
  value,
  label,
  subLabel,
  selected,
}: {
  idBase: string;
  size: SizeTokens;
  value: string;
  label: string;
  subLabel?: string;
  selected: boolean;
}) {
  const id = `${idBase}-${value}`;
  // TODO: We should probably switch this over to a proper createRadioGroup
  // implementation and make sure the whole item is tappable. Currently the user
  // has to tap on the label or radio circle.
  return (
    <XStack
      alignItems="center"
      gap="$3"
      boc={selected ? "$borderColorFocus" : "$borderColor"}
      bw={selected ? "$1" : "$0.5"}
      margin={selected ? "$0" : "$0.5"}
      br="$4"
      p="$3"
    >
      <RadioGroup.Item value={value} id={id} size={size}>
        <RadioGroup.Indicator />
      </RadioGroup.Item>

      <YStack f={1}>
        <Label size="$4" htmlFor={id} pb="$1" lineHeight="$4">
          {label}
        </Label>
        {subLabel && <Paragraph2 theme="alt1">{subLabel}</Paragraph2>}
      </YStack>
    </XStack>
  );
}

export const RadioGroupField = ({
  options,
  native = true,
  ...props
}: {
  options: RadioGroupItem[];
  size?: SizeTokens;
} & Pick<RadioGroupProps, "native">) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<string>();
  const { label, isOptional } = useFieldInfo();
  const { hideOptionalLabel } = useFormFieldOptions();
  const id = useId();
  const inputId = `${id}_radio_input`;
  const disabled = isSubmitting;

  return (
    <Theme name={error ? "red_alt3" : "granted"} forceClassName>
      <Fieldset>
        {!!label && (
          <Label size={props.size || "$3"} pb="$1" pl={0}>
            {label}
            {isOptional && !hideOptionalLabel && ` (Optional)`}
          </Label>
        )}

        <RadioGroup
          disabled={disabled}
          aria-labelledby="Select one item"
          value={field.value}
          onValueChange={field.onChange}
          native={native}
          name="form"
        >
          <YStack alignItems="center" gap="$2">
            {options.map(item => {
              return (
                <RadioGroupItemWithLabels
                  idBase={inputId}
                  size="$3"
                  key={item.value}
                  value={item.value}
                  label={item.label}
                  subLabel={item.subLabel}
                  selected={field.value === item.value}
                />
              );
            })}
          </YStack>
        </RadioGroup>
        <FormError errorMessage={error?.errorMessage} />
      </Fieldset>
    </Theme>
  );
};
