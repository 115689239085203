/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type React from "react";
import { useState } from "react";
import { type TextInput } from "react-native";
import type {
  ColorProp,
  ColorTokens,
  FontSizeTokens,
  TamaguiElement,
} from "tamagui";
import {
  Button as TButton,
  Input as TInput,
  View,
  XGroup,
  createStyledContext,
  getFontSize,
  isWeb,
  styled,
  useGetThemedIcon,
  withStaticProperties,
} from "tamagui";

import { type RadiusKeys } from "../../themes/token-radius";
import { type SpaceKeys } from "../../themes/token-space";
import { Label, Paragraph } from "../Typography";

const defaultContextValues = {
  size: "$4",
  scaleIcon: 1.2,
  color: undefined,
} as const;

const InputContext = createStyledContext<{
  size: FontSizeTokens;
  scaleIcon: number;
  color?: ColorTokens;
}>(defaultContextValues);

const defaultInputGroupStyles = {
  size: "$4",
  fontFamily: "$body",
  color: "$color",
  borderWidth: "$0.5",
  margin: "$0.5",
  outlineWidth: 0,

  ...(isWeb
    ? {
        tabIndex: -1,
      }
    : {
        focusable: true,
      }),

  borderColor: "$borderColor",
  backgroundColor: "$background",
  placeholderTextColor: "$placeholder",

  // this fixes a flex bug where it overflows container
  minWidth: "100%",

  hoverStyle: {
    borderColor: "$borderColorHover",
  },

  disabledStyle: {
    borderColor: "$strokeSecondary",
    backgroundColor: "$groupedSecondary",
    color: "$textSecondary",
    disabled: true,
  },

  focusStyle: {
    borderColor: "$borderColorFocus",
    borderWidth: "$1",
    margin: 0,
  },
} as const;

const InputGroupFrame = styled(XGroup, {
  justifyContent: "space-between",
  context: InputContext,
  variants: {
    unstyled: {
      false: defaultInputGroupStyles,
    },
    applyFocusStyle: {
      ":boolean": (val, { props }) => {
        if (val) {
          return props.focusStyle || defaultInputGroupStyles.focusStyle;
        }
      },
    },
    disabled: {
      ":boolean": val => {
        if (val) {
          return defaultInputGroupStyles.disabledStyle;
        }
      },
    },
    size: {
      "...size": () => {
        return {
          borderRadius: "$6",
        };
      },
    },
  } as const,
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1" ? true : false,
  },
});

const FocusContext = createStyledContext({
  // eslint-disable-next-line unused-imports/no-unused-vars
  setFocused: (val: boolean) => {},
  focused: false,
});

const InputGroupImpl = InputGroupFrame.styleable((props, forwardedRef) => {
  const { children, ...rest } = props;
  const [focused, setFocused] = useState(false);

  return (
    <FocusContext.Provider focused={focused} setFocused={setFocused}>
      <InputGroupFrame applyFocusStyle={focused} ref={forwardedRef} {...rest}>
        {children}
      </InputGroupFrame>
    </FocusContext.Provider>
  );
});

const InputFrame = styled(TInput, {
  unstyled: true,
  fontFamily: "$body",
  width: "100%",
  f: 1,
  context: InputContext,
});

const InputImpl = InputFrame.styleable((props, ref: React.Ref<TextInput>) => {
  const { setFocused } = FocusContext.useStyledContext();
  const { size } = InputContext.useStyledContext();
  const { disabled, onBlur, ...rest } = props;
  return (
    <InputFrame
      ref={ref}
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={e => {
        if (onBlur) {
          onBlur(e);
        }
        setFocused(false);
      }}
      size={size}
      editable={!disabled}
      {...rest}
    />
  );
});

const InputSection = styled(XGroup.Item, {
  justifyContent: "center",
  alignItems: "center",
  context: InputContext,
});

const Button = styled(TButton, {
  context: InputContext,
  justifyContent: "center",
  alignItems: "center",

  variants: {
    size: {
      "...size": (val = "$true", { tokens }) => {
        if (typeof val === "number") {
          return {
            paddingHorizontal: 0,
            height: val,
            borderRadius: val * 0.2,
          };
        }
        return {
          paddingHorizontal: 0,
          height: val,
          borderRadius: tokens.radius[val as RadiusKeys],
        };
      },
    },
  } as const,
});

// Icon starts

const InputIconFrame = styled(View, {
  justifyContent: "center",
  alignItems: "center",
  context: InputContext,

  variants: {
    size: {
      "...size": (val, { tokens }) => {
        return {
          paddingHorizontal: tokens.space[val as SpaceKeys],
        };
      },
    },
  } as const,
});

const getIconSize = (size: FontSizeTokens, scale: number) => {
  return (
    (typeof size === "number"
      ? size * 0.5
      : getFontSize(size as FontSizeTokens)) * scale
  );
};

const InputIcon = InputIconFrame.styleable<{
  scaleIcon?: number;
  color?: ColorTokens;
}>((props, ref: React.Ref<TamaguiElement>) => {
  const { children, ...rest } = props;
  const inputContext = InputContext.useStyledContext();
  const focusContext = FocusContext.useStyledContext();
  const { size = "$true", scaleIcon = 1 } = inputContext;

  const color = focusContext.focused ? "$borderColorFocus" : "$textSecondary";
  const iconSize = getIconSize(size, scaleIcon);

  const getThemedIcon = useGetThemedIcon({
    size: iconSize,
    color: color as ColorProp,
  });
  return (
    <InputIconFrame paddingRight="$2" paddingLeft="$3.5" ref={ref} {...rest}>
      {getThemedIcon(children)}
    </InputIconFrame>
  );
});

const InputContainerFrame = styled(View, {
  context: InputContext,
  flexDirection: "column",

  variants: {
    size: {
      "...size": () => {
        return {};
        // Removed from default Bento, since we achieve exact spacing with label
        // padding instead.

        // return { gap: tokens.space[val as SpaceKeys].val * 0.2 };
      },
    },
    color: {
      "...color": () => ({}),
    },
    // gapScale: {
    //   ":number": {} as any,
    // },
  } as const,

  defaultVariants: {
    size: "$4",
  },
});

const InputLabel = styled(Label, {
  context: InputContext,
  fontFamily: "$body",
  pl: 0,
  pb: "$2",
  color: "$color",
  fontWeight: "500",
  variants: {
    size: {
      "...size": (val, { font }) => {
        // Downsize the label one size from the input font size.
        const size = parseInt(val.toString().replace("$", ""));
        if (size > 0) {
          return {
            // @ts-expect-error tamagui types make no sense
            fontSize: font?.size?.[`$${size - 1}`]?.val ?? "$3",
            // @ts-expect-error tamagui types make no sense
            lineHeight: font?.lineHeight?.[`$${size - 1}`]?.val ?? "$3",
          };
        }
        return {
          fontSize: val,
          lineHeight: val,
        };
      },
    },
  } as const,
  defaultVariants: {
    size: "$4",
  },
});

const InputXGroup = styled(XGroup, {
  context: InputContext,

  variants: {
    size: {
      "...size": (val, { tokens }) => {
        const radiusToken =
          tokens.radius[val as RadiusKeys] ?? tokens.radius["true"];
        return {
          borderRadius: radiusToken,
        };
      },
    },
  } as const,
});

const InputInfo = styled(Paragraph, {
  context: InputContext,

  variants: {
    size: {
      "...fontSize": (val, { font }) => {
        if (!font) {
          return;
        }
        // @ts-expect-error tamagui types make no sense
        const fontSize = font.size?.[val]?.val ?? 0 * 0.8;
        const lineHeight =
          (font.lineHeight?.[val] as { val: number })?.val * 0.8;
        const fontWeight = font.weight?.["$2"];
        const letterSpacing = font.letterSpacing?.[val];
        const textTransform = font.transform?.[val];
        const fontStyle = font.style?.[val];
        return {
          fontSize,
          lineHeight,
          fontWeight,
          letterSpacing,
          textTransform,
          fontStyle,
        };
      },
    },
  } as const,
});

export const BentoInput = withStaticProperties(InputContainerFrame, {
  Box: InputGroupImpl,
  Area: InputImpl,
  Section: InputSection,
  Button,
  Icon: InputIcon,
  Info: InputInfo,
  Label: InputLabel,
  XGroup: withStaticProperties(InputXGroup, { Item: XGroup.Item }),
});
