import { useTsController } from "@ts-react/form";
import React, { useId } from "react";
import type { TextInput } from "react-native";
import { z } from "zod";

import { BentoInput } from "../bento/inputParts";
import { Fieldset } from "./Fieldset";
import { FormError } from "./FormError";

export const SecurityAnswersSchema = z.object({
  answers: z.array(z.string().min(1, "Answer cannot be empty")),
});
export const SecurityAnswersField = (props: { answers: string[] }) => {
  const inputRef = React.useRef<TextInput | null>(null);
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<z.infer<typeof SecurityAnswersSchema>>();
  const id = useId();
  const disabled = isSubmitting;

  const setInputFocus = () => {
    if (inputRef.current && !inputRef.current.isFocused()) {
      inputRef.current.focus();
    }
  };

  return (
    <>
      {props.answers.map((answer, i) => (
        <Fieldset key={`answer${i}`}>
          <BentoInput
            theme={error ? "red_alt2" : "granted"}
            key={`${id}_input${i}`}
          >
            <BentoInput.Label htmlFor={`${id}_input${i}`}>
              {answer}
            </BentoInput.Label>
            <BentoInput.Box onPress={() => setInputFocus()} disabled={disabled}>
              <BentoInput.Area
                id={`${id}_input${i}`}
                overflow="hidden"
                flex={1}
                value={field.value ? field.value.answers[i] : ""}
                onChangeText={text => {
                  const newAnswers = field.value
                    ? [...field.value.answers]
                    : new Array(props.answers.length).fill(undefined);
                  newAnswers[i] = text;
                  return field.onChange({ answers: newAnswers });
                }}
                onBlur={field.onBlur}
                disabled={disabled}
                ref={ref => {
                  field.ref(ref);
                  inputRef.current = ref;
                }}
              />
            </BentoInput.Box>
            <FormError errorMessage={error?.errorMessage} />
          </BentoInput>
        </Fieldset>
      ))}
    </>
  );
};
