import { useFieldInfo, useTsController } from "@ts-react/form";
import { useId } from "react";
import { Fieldset, type InputProps, Label, XStack } from "tamagui";
import { z } from "zod";

import { BentoInput } from "../bento/inputParts";
import { FormError } from "./FormError";

export const AddressSchema = z.object({
  street: z.string().min(4),
  zipCode: z.string().regex(/\d{5}/, "ZIP code should contain only 5 integers"),
});

export const AddressField = ({ size }: Pick<InputProps, "size">) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<z.infer<typeof AddressSchema>>();
  const { label } = useFieldInfo();
  const id = useId();
  const disabled = isSubmitting;

  return (
    <Fieldset gap="$1">
      <Label size="$3" pb="$1" pl={0}>
        {label}
      </Label>

      <XStack
        $sm={{ flexDirection: "column" }}
        $gtSm={{ flexWrap: "wrap" }}
        gap="$2"
      >
        <Fieldset>
          <BentoInput
            theme={error?.street ? "red_alt2" : "granted"}
            size={size || "$4"}
          >
            {!!label && (
              <BentoInput.Label htmlFor={`${id}-street`}>
                Street Address
              </BentoInput.Label>
            )}
            <BentoInput.Box disabled={disabled}>
              <BentoInput.Area
                id={`${id}-street`}
                placeholder="4116 Pretty View Lane"
                overflow="hidden"
                flex={1}
                value={field.value?.street}
                onChangeText={street =>
                  field.onChange({ ...field.value, street })
                }
                onBlur={field.onBlur}
                disabled={disabled}
                ref={field.ref}
              />
            </BentoInput.Box>
            <FormError errorMessage={error?.street?.errorMessage} />
          </BentoInput>
        </Fieldset>

        <Fieldset>
          <BentoInput
            theme={error?.zipCode ? "red" : "granted"}
            size={size || "$4"}
          >
            {!!label && (
              <BentoInput.Label htmlFor={`${id}-zip-code`}>
                US ZIP Code
              </BentoInput.Label>
            )}
            <BentoInput.Box disabled={disabled}>
              <BentoInput.Area
                id={`${id}-zip-code`}
                placeholder="12345"
                overflow="hidden"
                flex={1}
                value={field.value?.zipCode}
                onChangeText={zipCode =>
                  field.onChange({ ...field.value, zipCode })
                }
                onBlur={field.onBlur}
                disabled={disabled}
                ref={field.ref}
              />
            </BentoInput.Box>
            <FormError errorMessage={error?.zipCode?.errorMessage} />
          </BentoInput>
        </Fieldset>
      </XStack>
    </Fieldset>
  );
};
