import { Fieldset as FieldsetOG } from "tamagui";

import { type GetProps, styled } from "@tamagui/core";

export const Fieldset = styled(FieldsetOG, {
  name: "Fieldset",
  tag: "fieldset",

  gap: "$2",
});

export type FieldsetProps = GetProps<typeof Fieldset>;
