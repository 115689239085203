import { InputField, type InputFieldProps } from "./InputField";

export const zipCodeRegex = /^\d{5}$/;

export const formatZip = (text: string): string => {
  const regex = /(\d{0,5})/;
  const match = text.match(regex);

  if (match) {
    return match[1] ?? "";
  }

  return "";
};

export const ZipCodeField = (props: InputFieldProps) => {
  return (
    <InputField
      formatInput={formatZip}
      inputMode="numeric"
      keyboardType="number-pad"
      maxLength={5}
      {...props}
    />
  );
};
