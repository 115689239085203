import { forwardRef } from "react";
import {
  type TamaguiElement,
  YStack,
  type YStackProps,
  withStaticProperties,
} from "tamagui";

const Wrapper = forwardRef<TamaguiElement, YStackProps>(
  function FormWrapper(props, ref) {
    return <YStack ref={ref} gap="$4" flex={1} width="100%" {...props} />;
  },
);

const Body = forwardRef<TamaguiElement, YStackProps>(function Body(props, ref) {
  return (
    <YStack
      ref={ref}
      gap="$2"
      pb="$6"
      $platform-web={{
        pb: 0,
      }}
      {...props}
    />
  );
});

const Footer = forwardRef<TamaguiElement, YStackProps>(
  function Footer(props, ref) {
    return <YStack ref={ref} pb="$4" gap="$4" {...props} />;
  },
);

export const FormWrapper = withStaticProperties(Wrapper, {
  Body,
  Footer,
});
