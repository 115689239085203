import { type PropsWithChildren, createContext, useContext } from "react";

export type FormFieldOptionsContextType = {
  hideOptionalLabel?: boolean;
};

export const FormFieldOptionsContext =
  createContext<FormFieldOptionsContextType>({
    hideOptionalLabel: false,
  });

export const FormFieldOptionsProvider = ({
  hideOptionalLabel,
  children,
}: PropsWithChildren<FormFieldOptionsContextType>) => {
  return (
    <FormFieldOptionsContext.Provider value={{ hideOptionalLabel }}>
      {children}
    </FormFieldOptionsContext.Provider>
  );
};

export const useFormFieldOptions = () => {
  return useContext(FormFieldOptionsContext);
};
