import { useFieldInfo, useTsController } from "@ts-react/form";
import { useId } from "react";
import { Fieldset, type SwitchProps, Theme } from "tamagui";
import { Label } from "tamagui";

import { CustomSwitch } from "../CustomSwitch";
import { FormError } from "./FormError";
import { useFormFieldOptions } from "./FormFieldOptionsProvider";

export const BooleanSwitchField = (
  props: Pick<SwitchProps, "size" | "native">,
) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<boolean>();
  const { label, isOptional } = useFieldInfo();
  const { hideOptionalLabel } = useFormFieldOptions();
  const id = useId();
  const inputId = `${id}_boolean_switch_input`;
  const disabled = isSubmitting;

  return (
    <Theme name={error ? "red_alt2" : "granted"} forceClassName>
      <Fieldset ai="flex-start">
        {!!label && (
          <Label htmlFor={inputId} size={props.size || "$3"} pb="$1" pl={0}>
            {label}
            {isOptional && !hideOptionalLabel && ` (Optional)`}
          </Label>
        )}
        <CustomSwitch
          disabled={disabled}
          checked={field.value}
          onCheckedChange={checked => field.onChange(checked)}
          ref={field.ref}
          id={inputId}
          key={inputId}
          opacity={1}
          size="$3.5"
          {...props}
        >
          <CustomSwitch.Thumb />
        </CustomSwitch>
        <FormError errorMessage={error?.errorMessage} />
      </Fieldset>
    </Theme>
  );
};
