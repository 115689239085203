import type React from "react";
import {
  SwitchFrame as FrameOG,
  type TamaguiElement,
  SwitchThumb as ThumbOG,
  createSwitch,
  styled,
} from "tamagui";

// Unstyled prop applied here because useDefaultVariants
// does not apply the unstyled prop it simply uses
// the styles coded in that variant which we do not want,
// however we do want the unstyled prop to be applied
const StyledThumbOG = ThumbOG.styleable(
  (props, ref: React.RefObject<TamaguiElement>) => (
    <ThumbOG {...props} ref={ref} unstyled></ThumbOG>
  ),
);

/**
 * this has a ts error because the variants are not
 * defined in the SwitchThumb component but in the
 * Switch component. This is because the SwitchThumb
 * component is not exported from the Switch component
 * and therefore cannot be used in the Switch component
 * variants. This is why we have to define the variants.
 *
 * The shadow styles have to be applied per true and false state
 * and not at the baseline otherwise it breaks it. I tried this as
 * a default in unstyled as well as passed in and on above in the first
 * styled component but it breaks the shadow styles.
 *
 * The size calculations were removed because they are not needed
 * maybe it was for andriod?(Check this)
 *
 * Can apply any constant styles to the SwitchThumb
 * here
 *
 */
const Thumb = styled(StyledThumbOG, {
  animation: "bouncyTight",

  backgroundColor: "white",
  shadowColor: "black",
  borderRadius: 1000,

  variants: {
    checked: {
      true: {
        shadowOffset: { width: -2, height: 1.5 },
        shadowOpacity: 0.2,
        shadowRadius: 1,
      },
      false: {
        shadowOffset: { width: 2, height: 1.5 },
        shadowOpacity: 0.2,
        shadowRadius: 1,
      },
    },
    unstyled: {
      true: {},
    },
  } as const,
});

/**
 * Unstyled prop applied here because useDefaultVariants
 * does not apply the unstyled prop it simply uses
 * the styles coded in that variant which we do not want,
 * however we do want the unstyled prop to be applied
 */
const StyledFrameOG = FrameOG.styleable(
  (props, ref: React.RefObject<TamaguiElement>) => (
    <FrameOG {...props} ref={ref} unstyled></FrameOG>
  ),
);

/**
 * ShadowOffset animate only gets passed down here to the thumb
 * If animate only is used on the thumb it somehow breaks the frame?
 * This is why we have to pass it down here.
 *
 * Can apply any constant styles to the SwitchFrame
 * here
 */
const Frame = styled(StyledFrameOG, {
  animateOnly: ["backgroundColor", "shadowOffset"],
  animation: "bouncyTight",

  borderRadius: 1000,
  padding: 2,

  variants: {
    checked: {
      true: {
        backgroundColor: "$navy",
      },
      false: { backgroundColor: "#E5E5E5" },
    },
  } as const,
});

export const CustomSwitch = createSwitch({
  Frame: Frame,
  Thumb: Thumb,
});
