import dayjs from "dayjs";

import { InputField, type InputFieldProps } from "./InputField";

export const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

// Formats a partial date string to add hyphens as the user types.
export const formatDate = (text: string): string => {
  const cleanText = text.replace(/\//g, "");
  const regex = /(\d{0,2})(\d{0,2})(\d{0,4})/;
  const match = cleanText.match(regex);

  if (match) {
    return `${match[1]}${match[2] ? "/" + match[2] : ""}${
      match[3] ? "/" + match[3] : ""
    }`;
  }
  return "";
};

export const formatDefaultDate = (value: string): string => {
  return dayjs(value).format("MM/DD/YYYY");
};

export const DateTextField = (props: InputFieldProps) => {
  return (
    <InputField
      formatInput={formatDate}
      inputMode="numeric"
      keyboardType="number-pad"
      maxLength={10}
      placeholder="MM/DD/YYYY"
      {...props}
    />
  );
};
