import { useStringFieldInfo, useTsController } from "@ts-react/form";
import React, { useId } from "react";
import { type TextInput } from "react-native";
import { type InputProps } from "tamagui";

import { type IconProps } from "@tamagui/helpers-icon";
import { Eye as EyeIcon, EyeOff as EyeOffIcon } from "@tamagui/lucide-icons";
import { type GetThemeValueForKey } from "@tamagui/web";

import { BentoInput } from "../bento/inputParts";
import { Fieldset } from "./Fieldset";
import { FormError } from "./FormError";
import { useFormFieldOptions } from "./FormFieldOptionsProvider";

export type TextFieldProps = Pick<
  InputProps,
  | "autoFocus"
  | "disabled"
  | "enablesReturnKeyAutomatically"
  | "inputMode"
  | "keyboardType"
  | "onSubmitEditing"
  | "placeholder"
  | "returnKeyType"
  | "secureTextEntry"
  | "size"
  | "autoCapitalize"
> & {
  leftIcon?: React.NamedExoticComponent<IconProps>;
  rightIcon?: React.NamedExoticComponent<IconProps>;
  scaleIcon?: number;
  iconProps?: IconProps;
  iconHorizontalPadding?: number | GetThemeValueForKey<"paddingHorizontal">;
  label?: string;
};

export const TextField = (props: TextFieldProps) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<string>();
  const {
    secureTextEntry = false,
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    scaleIcon = 1.25,
    label: explicitLabel,
    iconHorizontalPadding = "$3",
    iconProps,
    disabled: disabledProp,
    size,
    autoCapitalize,
    ...inputProps
  } = props;
  const {
    placeholder,
    isOptional,
    maxLength,
    isEmail,
    label: stringFieldLabel,
  } = useStringFieldInfo();
  const { hideOptionalLabel } = useFormFieldOptions();
  const inputRef = React.useRef<TextInput | null>(null);
  const [secure, setSecure] = React.useState(secureTextEntry || false);
  const id = useId();
  const inputId = `${id}_text_field_input`;
  const disabled = isSubmitting || disabledProp;
  const label = explicitLabel || stringFieldLabel;

  const setInputFocus = () => {
    if (inputRef.current && !inputRef.current.isFocused()) {
      inputRef.current.focus();
    }
  };

  return (
    <Fieldset>
      <BentoInput
        theme={error ? "red_alt2" : "granted"}
        size={size || "$4"}
        key={inputId}
      >
        {!!label && (
          <BentoInput.Label htmlFor={inputId}>
            {label}
            {isOptional && !hideOptionalLabel && ` (Optional)`}
          </BentoInput.Label>
        )}
        <BentoInput.Box onPress={() => setInputFocus()} disabled={disabled}>
          {LeftIcon && (
            <BentoInput.Icon paddingHorizontal={iconHorizontalPadding}>
              <LeftIcon
                color="$color12"
                strokeWidth={2}
                scale={scaleIcon}
                {...iconProps}
              />
            </BentoInput.Icon>
          )}
          <BentoInput.Area
            id={inputId}
            paddingLeft={LeftIcon ? 0 : undefined}
            paddingRight={secureTextEntry || RightIcon ? 0 : undefined}
            placeholder={placeholder}
            maxLength={maxLength}
            overflow="hidden"
            flex={1}
            spellCheck={isEmail ? false : undefined}
            autoCapitalize={autoCapitalize ?? (isEmail ? "none" : undefined)}
            autoCorrect={isEmail ? false : undefined}
            inputMode={isEmail ? "email" : props.inputMode || undefined}
            keyboardType={
              isEmail ? "email-address" : props.keyboardType || undefined
            }
            secureTextEntry={secure}
            // Empty string instead of undefined so that the component
            // does not transition from "unmanaged" to "managed" (which
            // displays an error log in the web console).
            value={field.value ?? ""}
            onChangeText={text =>
              isEmail
                ? field.onChange(text.toLowerCase())
                : field.onChange(text)
            }
            onBlur={field.onBlur}
            disabled={disabled}
            ref={ref => {
              field.ref(ref);
              inputRef.current = ref;
            }}
            {...inputProps}
          />
          {secureTextEntry && (
            <BentoInput.Icon
              onPress={() => setSecure(!secure)}
              paddingHorizontal={iconHorizontalPadding}
            >
              {secure ? (
                <EyeIcon strokeWidth={2} scale={scaleIcon} {...iconProps} />
              ) : (
                <EyeOffIcon strokeWidth={2} scale={scaleIcon} {...iconProps} />
              )}
            </BentoInput.Icon>
          )}
          {RightIcon && (
            <BentoInput.Icon paddingHorizontal={iconHorizontalPadding}>
              <RightIcon
                color="$color12"
                strokeWidth={2}
                scale={scaleIcon}
                {...iconProps}
              />
            </BentoInput.Icon>
          )}
        </BentoInput.Box>
        <FormError errorMessage={error?.errorMessage} />
      </BentoInput>
    </Fieldset>
  );
};
